.Background-Display-Image-Container{
  position: relative;
  text-align: center;
  /* background-color: rgb(0, 0, 0); */
  /* opacity: 0.3; */
  width: 100%;
    height: 200px;
    background: linear-gradient(0deg, rgba(39, 34, 34, 0.54), rgba(9, 8, 9, 0.69)), url('https://www.cactusimages.co.uk/wp-content/uploads/2019/11/121119Oviso_0628.jpg');
    background-repeat:no-repeat;
    background-size: cover;
  
}

/* ExecutiveCouncil css here */
.executive-council-container{
  background-color: whitesmoke;
  padding: 10px;
}
.container-row{
  display: grid;
  width: 80%;
  margin: 0 auto;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.container-row img{
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.container-row-inner{
  display: grid;
  grid-template-columns: 1fr 3fr;
  border: 1px solid #ddd;
  background-color: white;
  /* background-color: #ebeaea; */
  box-shadow: 0 5px 20px rgba(160, 160, 160, 0.1);
  border-radius: 6px;
  padding: 20px;
}
.bg-img{
    
}
.Centered-Content{
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   color: white;
   font-family: cursive;
}

.my-about-container{
    /* background-color: black; */
    /* height: 500px; */
}

.About-Section-Container{
    /* background-color: black;
    color: white; */
    /* text-align: center; */
    /* background-image: url('https://www.fotoveda.co/img/s/v-10/p2274350132-4.jpg');
    background-size: fit; */
    /* height: 200px; */
    /* width: 100%; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top:2px;
    padding: 20px;
    grid-gap: 20px;
    /* margin: 0 auto;
    width: 80%; */
}

.About-Section-Container h2 {
    /* padding-top: 100px; */
    font-size: 40px;
    color: #2E7159;
}
.History-Phone-view{
  display: none;
}
.History-Container{
    padding: 15px;
    /* border: 1px solid #2E7159; */
    border: 1px solid #dddd;
    /* background-color: rgb(229, 231, 230); */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    line-height: 30px;
    border-radius: 5px;
  
   /* height: max-content; */
  
}
.History-Container1{
  display: grid;
  grid-column: 1/4;
  text-align: justify;
}
/* read more components css */
.text{
    display: inline;
    width: 100%;
    height: auto;
    line-height: 30px;
    text-align: justify;
    font-family: '"Rubik", sans-serif';
    /* background-color: red; */
  }
    
  .read-or-hide{
    color: rgb(15, 23, 128);
    cursor: pointer;
    font-weight: bolder;
  }

  .National-Executive-Team-Container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    width: 80%;
    grid-gap: 20px;
  }
  .National-Executive-Card{
    border: 1px solid #ddd;
    background-color: #d9ebfc;
  }
  .National-Executive-Card img{
    width: 100%;
    /* height: 400px; */
  }
  .Name-position-section{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: #2E7159;
  }

/* 
  contact us css starts here  */
  .Contact-Us-Container{
    margin: 0 auto;
    width: 80%;
    /* background-color: #2E7159; */
    /* height: 900px; */
    /* padding: 20px; */
  }
  .contact-section-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }
  .contact-section-container span{
    padding: 10px;
  }
  .contact-section-container h3{
    color: #2E7159;
  }
  .Icon-Sizer{
      font-size: 44px;
      color: white;
      padding: 10px;
     
      background-color: #2E7159;
      border-radius: 10px;
 
  }

  /* Membership page css starts here */
  .Membership-Container{
    margin: 0 auto;
    width: 50%;
    
  }
  .Membership-Container h2{
    color: #2E7159;
  }

  /* Publications starts here */
  .Publication-Container{
    display: grid;
    margin: 0 auto;
    width: 70%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    margin-top: 30px;
  }
  .Publication-Container h1{
    color: #2E7159;
  }
  .Publication-Container p{
    line-height: 30px;
  }

  /* affilaiation pages css starts here */
  .Affiliation-Container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    margin-top: 50px;
    grid-gap: 50px;
    width: 80%;
  }

  /* Tablet view css starts here */
  @media screen and (max-width: 1250px){
    .container-row{
      display: grid;
      width: 95%;
      margin: 0 auto;
      grid-gap: 20px;
      /* background-color: #2E7159; */
      grid-template-columns: repeat(2, 1fr);
    }
  }

  /* phone view css starts here */
  @media screen and (max-width: 850px){
    .History-Phone-view{
      display: unset;
    }
    .History-Desktop-view{
      display: none;
    }
    .executive-council-container div{
      font-size: 15px;
      color: #2E7159;
      padding: 5px;
    }
    .container-row{
      display: grid;
      width: 95%;
      margin: 0 auto;
      grid-gap: 20px;
      /* background-color: #2E7159; */
      grid-template-columns: repeat(1, 1fr);
    }
    .History-Container{
      display: grid;
      grid-column: 1/4;
      text-align: justify;
    }
   
    .Background-Display-Image-Container{
        position: relative;
        text-align: center;
        /* background-color: rgb(0, 0, 0); */
        /* opacity: 0.3; */
          width: 100%;
          height: 100px;
          background: linear-gradient(0deg, rgba(39, 34, 34, 0.54), rgba(9, 8, 9, 0.69)), url('https://www.cactusimages.co.uk/wp-content/uploads/2019/11/121119Oviso_0628.jpg');
          background-repeat:no-repeat;
          background-size: cover;
        
      }
      .Centered-Content{
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
       color: white;
       font-family: cursive;
       font-size: small;
    }

    .About-Section-Container{
       
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-top:2px;
        padding: 20px;
        grid-gap: 20px;
        /* margin: 0 auto;
        width: 80%; */
    }
    .National-Executive-Team-Container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 0 auto;
        width: 80%;
        grid-gap: 20px;
      }
      .contact-section-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        padding: 20px;
      }
      .Membership-Container{
        margin: 0 auto;
        width: 90%;
        
      }
      .Publication-Container{
        display: grid;
        margin: 0 auto;
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        margin-top: 30px;
      }
      .Affiliation-Container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 0 auto;
        margin-top: 50px;
        grid-gap: 50px;
        width: 90%;
      }
  }