.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  /* background: darkcyan; */
  left: 0;
  margin-top: 0px;
  bottom: 10;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 100%;
  z-index: 9;
}
.Close-Btn{
  float: right;
  margin-top: 15px;
  /* padding: 40px; */
  font-size: 30px;
  padding-right: 50px;
}
.menuNav.showMenu {
  width: 100%;
  background: #000604;
  color: white;
}

.Menu-Icon-Container a {
  display: block;
  padding: 25px 25px;
  text-decoration: none;
  /* color: black; */
  border-bottom: 1px solid #dddd;
  color: white;
  font-size: 15px;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
}

/* .menuNav li:first-child {
  margin-top: 7rem;
} */






body{
  /* background-color: #EFF7FF; */
  /* width: 100%; */
  /* background-color: #E1E6F1; */
}
.Modal_Container{
  /* background-color: red; */
  width: 100%;
}
.Mobile-Links a{
  padding-top: 24px;
  text-decoration: none;
}
.Modal_Container a {
  padding: 32px;
}
.Nuts-Logo{
  padding-top: 12px;
}

.Header-Section{
  border-bottom: 1px solid #ddd;
  margin-bottom: 2px;
  /* position: fixed; */
  /* padding-bottom: 20px; */
  /* background-color: rgb(255, 255, 255); */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5,1fr);
}
.Nav-Logo{
  display: flex;
  padding-left: 52px;
  /* background-color: red; */

}
.signup-btn{
  background-color: #2E7159;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 8px;
}
.Nav-Logo a{
  text-decoration: none;
  color: black;
}
.Nav-Logo h3 {
  padding-top: 7px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 
  /* color: blue; */
  /* padding-left: 12px; */
  font-weight: bolder;
}
.Nav-Items{
  display: grid;
  grid-column: 2/6;
  
  padding-top: 15px;
  float: right;
  /* font-size: 12px; */
  /* background-color: yellowgreen; */
 
}
.Nav-Items nav a {
  padding: 18px;
  color: black;
  font-weight: 400;
  font-family: "Noto Sans","Segoe UI",Avenir,Helvetica,Arial,sans-serif;
  text-decoration: none;
  pointer-events: stroke;
  /* font-size: small; */
  
}
.Nav-Items nav a:hover{
  /* border-bottom: 5px solid #2E7159; */
  /* background-color: #2E7159; */
  border-radius: 7px;
  background-color: whitesmoke;
  color: #2E7159;
  /* font-weight: 400; */
  /* color: white; */
  
}
.Nav-Items nav{
  float: right;
  
 
}
.Menu-Icon-Container{
  opacity: 0.01;
  display: hidden;
}


/* Home page css starts here for main body */

/* presidential container css here */
.President-Container{
  background-color: #2E7159;
  width: 100%;
  border: 1px solid #2E7159;
  /* padding: 20px;
  height: 500px; */
}
.President-Container-Inner{
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0 auto;
  width: 90%;
}
.President-Image-Holder{
  background-color: #2E7159;
  padding: 40px;
  color: white;
 
}
.Desk-Of-President1{
  opacity: 0.01;
}
.President-Image{
  width: 474px;
  height: 434px;
}
.President-Writeup-Holder{
  background-color: #2E7159;
  color: white;
  padding: 20px;
}
.President-Message-Phone-View{
  display: none;
}

/* css upcoming events and activities here */
.Events-Container{
  /* background-color: rebeccapurple; */
  margin: 0 auto;
  width: 60%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  /* width: 70%;
  height: 1000px; */
}
.Upcoming-Events-Header{
  font-size: 23px;
  text-align: center;
  font-weight: bold;
}
/* .Events-Container-Outer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} */
.Events-Container-Inner{
  display: grid;
  grid-template-columns: 1fr 3fr;
  /* height: 210px; */
  width:100%;
  /* background-color: azure; */
  /* position: absolute; */
  border: 1px solid #ddd;
}
.Event-Date-Section{
  border-right: 1px solid #ddd;
  width: 60%;
}
.Event-Date-Section-Inner{
  padding: 10px;
  margin: 30px;
  width: 75%;
  /* height: 88px; */
  background-color: #2E7159;
  color: white;
  text-align: center;
  border-radius: 5px;
}
/* .Event-Date-Section-Inner h4{
  font-weight: bolder;
  font-size: larger;
} */
.Events-Title-Section{
  /* background-color: red; */
  /* padding: 40px; */
}
.Events-Title-Section-Inner{
  text-decoration: underline;
  padding-top: 20px;
  /* padding-left: 30px; */
  bottom: 0px;
}
.Events-Title-Section-Inner p{
  font-weight: bold;
}
.Events-Title-Section-Inner1{
  display: flex;
  padding-top: 20px;
  font-size: 14px;
 
}
.Events-Title-Section-Inner1 p{
  font-weight: light;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.Time{
  padding-left: 30px;
  float: right;
}
.View-All-Events-Link{
  margin: 0 auto;
  width: 50%;
  padding: 40px;
}
.All-events{
  float: right;
  text-decoration: underline;
  color: blue;
  font-weight: normal;
  
}

/* chapter page css starts here */
.Chapter-Container{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-gap: 20px;
  padding: 20px;
}
.Chapter-Card{
 
  padding: 10px;
  border: 1px Solid #2E7159;
  border-radius: 5px;
  background-color: #ede8e8;
}
.Chapter-Card h4{
  color: #2E7159;
}
.Chapter-Card span{
  font-family: fantasy;
  font-size: 12px;
  font-weight: lighter;

}
/* Services What we do Css Begins here */
.Services-Overall-Container{
  /* background-color: rgb(222, 250, 249); */
  /* background-color: #e9e6e6; */
  background-color: #f5f8f7;
  padding: 20px;
}
.Headings-Services{
  margin: 0 auto;
  width: 50%;
  border: none;
  /* border-bottom: 3px solid #2E7159; */
  /* border-radius: 6px;
  border-bottom-style: dashed; */
}
.Services-Container{
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  /* background-color: blue; */
  width: 90%;
}
.Services-Card-Container{
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: blue; */
}
.Services-Card-Container{
  display: grid;
  background-color: white;
  /* grid-template-columns: 1fr 2fr; */
  border: 1px solid #ddd;
  padding: 20px;
  /* background-color: red; */
}
.Services-Card img{
  width: 100px;
  height: 100px;
  padding: 5px;
}
.What-we-do{
  /* color: #2E7159; */
  /* color: white; */
  font-weight: bold;
  text-align: center;
}



  /* we are Everywhere in Nigeria Container 
  statistics css begins here*/
.Statistics-Container{
  /* background-color: honeydew; */
/* background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%); */


  /* height: 100px; */
  margin-top: 30px;
  padding: 40px;
}
.Stastics-Count-section{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* background-color: #d9f8ed; */
  /* height: 150px; */
  margin: 0 auto;
  width: 60%;
  border-radius: 3px;
  /* padding: 5px; */
  border: 1px solid #ddd;
  background-color: #d9ebfc;
}
.Stastics-Count-section img{
  width: 60px;
  height: 60px;
  padding: 10px;
  align-items: center;
}
.Stastics-Count-section span{
  /* color: white; */
  font-weight: bolder;
  font-size: 28px;
  padding-left: 15px;
  margin-top: 40px;
  font-family: 'Times New Roman', Times, serif;
}
.Count{
  padding-top: 20px;
}
.Stat-Members, .Stats-States{
  display: flex;
  background-color: #2E7159;
  padding: 20px;
  color: white;
}
.Stats-Local-Chapters{
  display: flex;
  color: black;
  padding: 20px;
}


/* Get started css begins here  */
.Get-Started-Container{
  height: 500px;
  background-color: #2E7159;
  margin-top: 150px;
  margin-bottom: 150px;
}


/* News sections CSS Stars here  */
.News-Container{
  /* margin-top: 100px; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* background-color: beige; */
  padding: 30px;
}
.News-Card-Container{
 display: grid;
 grid-template-columns: repeat(3,1fr);
  /* background-color: #d9ebfc; */
}
.News-Card{
  /* border: 1px solid #ddd; */
  width: 429px;
  /* height: 450px; */
  padding: 10px;
}
.News-Card:hover{
  background-color: white;
  font-weight: bolder;
  color: #2E7159;
  border-radius: 5px;
}
.News-Image img{
  width: 100%;
  height: 300px;
}
.Detail-News-Container{
  margin: 0 auto;
  width: 70%;
}
.News-Title{
  color: #2E7159;
  font-size: 20px;
  padding-top: 20px;
}
.News-description{
  line-height: 30px;
}

/* footer css starts here  */
.Overall-Footer-Container{
  background-color: black;
  /* background-color: #184b39; */
  /* height: 400px; */
  color: white;
  /* margin-top: 360px; */
}
.Footer-Container{
  /* position: absolute; */
  display: grid;
  grid-template-columns: 2fr 1fr 1fr ;
  margin: 0 auto;
  width: 80%;
  grid-gap: 20px;
  padding-top: 50px;
}
.Footer-Container li  {
  padding: 12px;
  list-style-type: none;
  color: white;
  font-weight: lighter;
}
.Footer-Container li:hover{
  font-weight: bolder;
}
.Footer-Links a{
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: lighter;
}
.Footer-Links div{
  padding: 10px;
}
.Footer-Links a:hover{
  text-decoration: none;
  color: white;
  /* font-size: 18px; */
  font-weight: bolder;
}
.Footer-Container p{
  /* font-family: Arial, Helvetica, sans-serif; */
  font-weight: lighter;
  font-family: "Rubik", sans-serif;
  line-height: 30px;
}

/* tablets view css starts here */

@media screen and (max-width: 1178px){

  body{
    margin: 0 auto;
    width: 100%;
  }
 
  .Nav-Items nav{
    display: none;
    
   
  }
  .Nav-Items{
    display: grid;
    grid-column: 2/4;
    padding-top: 15px;
    float: right;
    
   
  }
  .Nav-Items{
    display: grid;
    grid-column: 2/4;
    padding-top: 15px;
    float: right;
    /* font-size: 12px; */
    /* background-color: yellowgreen; */
   
  }
  .Menu-Icon-Container{
    float: right;
    opacity: 1;
    color: green;
    padding-left: 160%;
    /* padding-right: 15px; */
    font-size: 23px;
  }
  .Menu-Icon-Container Modal {
    width: 100%;
  }
 .MenuIcon{
  font-size: 40px;
 }

 .President-Image{
  width: 374px;
  height: 334px;
}
.President-Message-Desktop-View{
  display: none;
}
.President-Message-Phone-View{
  display: unset;
}
  .News-Container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .News-Card-Container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    width: 90%;
  }
  .Chapter-Container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    padding: 20px;
  }
  
}


/* phone view nav css */
@media screen and (max-width: 850px){

  .App{
    /* margin: 0 auto;
    width: 100%; */
    /* background-color: red; */
    padding: 0px;
  }
  .Header-Section{
 position: static;
 height: 60px;
 /* box-shadow: 0 0 9px 3px rgba(132, 131, 131, 0.2); */

  }
  /* .Menu-Icon-Container{
    padding-left: 85%;
  } */
  .App {
    font-size: 16px;
    font-weight: 300;
    /* line-height: 20px; */
    /* text-align: justify; */
  }
  .Nav-Items nav{
    display: none;
    /* padding-top: 25px; */
    /* background-color: yellowgreen; */
   
  }
  .Nav-Items{
    display: grid;
    grid-column: 2/4;
    padding-top: 15px;
    float: right;
    /* font-size: 12px; */
    /* background-color: yellowgreen; */
   
  }
  .Nav-Logo{
    
    padding-left: 12px;
    /* background-color: red; */
  
  }
  .Menu-Icon-Container{
    /* float: right; */
    opacity: 1;
    /* padding-right: 15px; */
    font-size: 23px;
  }
  .Menu-Icon-Container Modal {
    width: 100%;
  }
 .MenuIcon{
  font-size: 40px;
 }
  .Home-display-pic{
    padding: 24px;
    width: auto;
    height: 200px;
  }
  .President-Container{
    /* background-color: rgb(213, 168, 168); */
    width: 80;
    /* padding: 20px;
    height: 1300px; */
  }
  .President-Container-Inner{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
    width: 100%;
    
  }
  .President-Image-Holder{
    background-color: white;
    padding: 10px;
    margin: 0 auto;
    width: 90%;
   
  }
  .President-Image{
    width: 324px;
    height: 334px;
  }
  .President-Writeup-Holder p{
    font-family: 'Times New Roman', Times, serif;
  }
  .President-Message-Desktop-View{
    display: none;
  }
  .President-Message-Phone-View{
    display: unset;
  }
.Events-Container-Outer{
  margin: 0 auto;
  width: 96%;
}
  .Events-Container{
    /* background-color: rebeccapurple; */
    
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    /* height: 1000px; */
  }
  .Events-Container-Inner{
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* height: 150px; */
    width: 100%;
    /* position: absolute; */
    border: 1px solid #ddd;
  }
  .Event-Date-Section-Inner{
    padding: 5px;
    margin: 20px;
    width: 70%;
    /* height: 68px; */
    background-color: #2E7159;
    color: white;
    text-align: center;
    border-radius: 5px;
  }
  .Events-Title-Section-Inner{
   
    padding-top: 10px;
    padding-left: 0px;
   font-weight: lighter;
  }
  .Events-Title-Section-Inner p{
    font-weight: light;
    font-size: 13px;
    text-decoration: none;
  }
  .Events-Title-Section-Inner1{
    display: grid;
    padding-top: 1px;
    grid-template-columns: 2fr 1fr;
   
  }
  .Time{
    padding: 5px;
  }
  .Events-Title-Section-Inner1 p{
    font-size: 12px;
    font-weight: 300;
  }
  .View-All-Events-Link{
    margin: 0 auto;
    width: 90%;
    padding: 10px;
  }
  .All-events{
    float: right;
    text-decoration: underline;
    color: blue;
    font-size: small;
    font-weight: normal;
    
  }
  .Desk-Of-President{
    display: none;
    background-color: red;
  }
  .Desk-Of-President1{
    opacity: 0.9;
    color: #000604;
    text-align: center;
    font-size: small;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  .President-Name{
    color: black;
    text-align: center;
  }

  .Services-Container{
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    /* background-color: blue; */
    width: 100%;
  }
  .Headings-Services{
    margin: 0 auto;
    width: 80%;
  }
  .Services-Card img{
    margin-top: 30px;
    width: 70px;
    height: 70px;
  }
  
  
  .Stastics-Count-section{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: #d9f8ed;
    /* height: 100px; */
    margin: 0 auto;
    width: 90%;
  }
  .Stastics-Count-section img{
    width: 50px;
    height: 50px;
    padding: 5px;
    align-items: center;
  }
  .Stastics-Count-section span{
    /* color: white; */
    font-weight: bolder;
    font-size: 18px;
  }
  .Stat-Members, .Stats-States{
    background-color: #2E7159;
    /* background-color: rgb(175, 177, 249); */
    padding: 10px;
  }

  .News-Container{
   display: grid;
   grid-template-columns: repeat(1,1fr);
    /* background-color: beige; */
    padding: 0px;
  }
  .News-Card-Container{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    /* margin: 0 auto;
    width: 100%; */
  }
  .News-Card{
    /* border: 1px solid #ddd; */
    width: 100%;
    height: 350px;
    padding: 10px;
  }
  .News-Image img{
    width: 100%;
    height: 200px;
  }
  .Detail-News-Container{
    margin: 0 auto;
    width: 100%;
  }
  .News-Title-Desc1{
    color: #2E7159;
    /* font-size: 20px; */
    /* padding-top: 20px; */
    /* padding: 10px; */
  }
  .News-description{
    padding: 10px;
    text-align: justify;
  }
  .Time-Date-Section{
   display: inline-block;
   background-color: #2E7159;
   height: 500px;
  }


  .Overall-Footer-Container{
    background-color: #2E7159;
    /* height: 400px; */
    color: white;
    /* margin-top: 60px; */
  }
  .Footer-Container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 100px;
  }
  .Footer-Container li {
    padding: 7px;
    list-style-type: none;
    font-weight: lighter;
  }

  .Chapter-Container{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 20px;
    padding: 20px;
  }
}